import React, { useContext, useEffect, useRef, useState } from 'react'
import {
    BeakerIcon,
    BriefcaseIcon,
    CpuChipIcon,
    CloudIcon,
    ShieldExclamationIcon,
    TruckIcon,
    UserIcon,
    UsersIcon,
    HandThumbUpIcon,
    RectangleStackIcon,
    RectangleGroupIcon,
    GlobeEuropeAfricaIcon,
    LanguageIcon,
    CircleStackIcon,
    TableCellsIcon,
    MapPinIcon, BuildingOfficeIcon, ExclamationTriangleIcon, Bars4Icon, Bars3Icon
} from '@heroicons/react/24/outline'
import { Link, Outlet, useLocation } from 'react-router-dom'
import AuthController from './controllers/AuthController'
import AppContext from './appContext'
import UserController from './controllers/UserController'
import AppState from './controllers/AppState'
import MeasurementUnit from './controllers/MeasurementUnit'
import PermissionEnum from './controllers/PermissionEnum'
import Axios from 'axios'
import { isLocalhost } from './controllers/helper'

const signalR = require('@microsoft/signalr')

const navigation = [
    {
        permission: PermissionEnum.ManagementClientsPermission,
        name: 'clients',
        href: '/clients',
        icon: UsersIcon
    },
    {
        permission: PermissionEnum.ManagementFarmsPermission,
        name: 'farms',
        href: '/farms',
        icon: CloudIcon
    },
    {
        permission: PermissionEnum.ManagementProgramsPermission,
        name: 'programs',
        href: '/programs',
        icon: CpuChipIcon
    },
    {
        permission: PermissionEnum.ManagementApprovalsPermission,
        name: 'approvals',
        href: '/approvals',
        icon: HandThumbUpIcon
    },
    {
        permission: PermissionEnum.ManagementAgentPermission,
        name: 'agents',
        href: '/agents',
        icon: BriefcaseIcon
    },
    {
        permission: PermissionEnum.ManagementDistributorPermission,
        name: 'distributors',
        href: '/distributors',
        icon: TruckIcon
    },
    {
        permission: PermissionEnum.ManagementTemplatesPermission,
        name: 'templates',
        href: '/templates',
        icon: RectangleGroupIcon
    },
    {
        permission: PermissionEnum.ManagementCategoryPermission,
        name: 'categories',
        href: '/categories',
        icon: RectangleStackIcon
    },
    {
        permission: PermissionEnum.ManagementProductsPermission,
        name: 'products',
        href: '/products',
        icon: BeakerIcon
    },
    {
        permission: PermissionEnum.ManagementRegions,
        name: 'regions',
        href: '/regions',
        icon: GlobeEuropeAfricaIcon
    },
    {
        permission: PermissionEnum.ManagementLanguagePermission,
        name: 'language',
        href: '/languages',
        icon: LanguageIcon
    },
    {
        permission: PermissionEnum.ManagementCropPermission,
        name: 'crops',
        href: '/crops',
        icon: CircleStackIcon
    },
    {
        permission: PermissionEnum.ManagementCultivarPermission,
        name: 'cultivars',
        href: '/cultivars',
        icon: TableCellsIcon
    },
    {
        permission: PermissionEnum.ManagementAddress,
        name: 'addresses',
        href: '/addresses',
        icon: MapPinIcon
    },
    {
        permission: PermissionEnum.ManagementRolesPermissions,
        name: 'roles',
        href: '/roles',
        icon: ShieldExclamationIcon
    },
    {
        permission: PermissionEnum.ManagementCompanyPermission,
        name: 'companies',
        href: '/companies',
        icon: BuildingOfficeIcon
    },
    {
        permission: PermissionEnum.ManagementUsersPermission,
        name: 'users',
        href: '/users',
        icon: UserIcon
    },
    {
        permission: PermissionEnum.ManageErrors,
        name: 'errors',
        href: '/errors',
        icon: ExclamationTriangleIcon
    }
]

function classNames (...classes: string[]): string {
    return classes.filter(Boolean).join(' ')
}


if (!isLocalhost()) {
    Axios.interceptors.response.use(undefined, error => {
        window.location.href = '/';
    });
}

function logout () {
    AuthController.logout().then(() => {
        window.location.href = '/'
    })
}

function setLanguage (setInitial: (state: AppState) => void, id: number) {
    UserController.setLangauge({
        id
    }).then(() => {
        AuthController.getAppState().then(resp => {
            // setInitial(resp.data)
            window.location.reload()
        })
    })
}

function setUnit (val: MeasurementUnit) {
    UserController.changeDisplayUnit({
        unit: val
    }).then(() => window.location.reload())
}
const signal = new signalR.HubConnectionBuilder()
    .withUrl('/messages')
    .withAutomaticReconnect()
    .build()

export default function App (props: { setInitial: (state: AppState) => void }) {
    const app = useContext(AppContext)
    const location = useLocation()
    const approvedNavigation = navigation.filter(item => app.hasPermission(item.permission))
    const [showPopup, setShopPopup] = useState(false)
    const [showUpdate, setShowUpdate] = useState(false)

    function windowClicked (event: MouseEvent) {
        setShopPopup(false)
    }

    useEffect(() => {
        signal.start().then(() => {
            signal.on('Version', (version: string) => {
                if (version !== app.initial.version) setShowUpdate(true) 
            })
            signal.send('ClientVersion', {version: app.initial.version})
        })

        window.addEventListener('click', windowClicked)
        return () => {
            window.removeEventListener('click', windowClicked)
        }
    }, [])

    return (
        <div>
            {showUpdate ? <div className='sticky top-0 z-50 w-full h-[5rem] border-8 bg-primary-600 px-10'>
                <div className='flex w-full h-[4rem] items-center justify-between'>
                        <div className='text-primary text-xl text-white'>
                            {app.word('new_version_available') + ". " + app.word('save_and_update')}.
                        </div>
                        <div className='btn bg-primary' onClick={() => {
                            setShowUpdate(false)
                            window.location.reload()
                        }}>
                            {app.word('update')}
                        </div>
                </div>
            </div> : null}
            {
                app.initial.isDev 
                    ? <div className='sticky top-0 z-50 w-full border-2 bg-red-500 px-10 py-2'>
                            <div className='text-primary text-xl text-white'>
                                This is a testing environment.
                            </div>
                        </div> 
                    : null
            }

            <div className="flex w-full bg-gray-800 items-center">

                <div className="pl-4 pr-8 py-2 text-center">
                    <div className="relative">
                        <img className="w-auto inline-block py-2" alt="logo" src="/sprites/logo.png"/>
                        <div className="absolute bottom-0 right-0 text-[0.675rem] -mr-8 -mb-1">
                            <span className="px-1 bg-gray-700 rounded-l-lg text-gray-300">Fertiliser</span>
                            <span className="px-1 bg-primary-600 rounded-r-lg text-white">v{app.initial.version}</span>
                        </div>
                    </div>
                </div>

                <div className="flex w-full flex-wrap">

                    {approvedNavigation.map((item) => <Link
                        to={item.href}
                        key={item.name}
                        className={classNames(
                            location.pathname === item.href ? 'block bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'flex-1 px-1 ms:px-2 py-2 text-base font-medium rounded-md text-center'
                        )}
                        onClick={() => {
                            showUpdate ? window.location.href = item.href : null
                        }}
                    >
                        <item.icon
                            className={classNames(
                                location.pathname === item.href ? 'text-primary-500' : 'text-gray-400 group-hover:text-gray-300',
                                'h-6 w-6 ms:h-8 ms:w-8 inline-block'
                            )}
                            aria-hidden="true"
                        />
                        <div className="text-white text-xs">{app.word(item.name)}</div>
                    </Link> )}
                </div>

                <div className="ml-auto flex-initial white text-right pr-2 relative cursor-pointer" onClick={event => {
                    setShopPopup(true)
                    event.stopPropagation()
                }}>

                    <div className="inline-block text-white whitespace-no-wrap text-sm">{app.initial.userDisplay}</div>
                    <Bars3Icon className="inline-block text-white h-6 h-6"/>

                    {showPopup
                        ? <div className="fixed right-1 bg-white border rounded  z-50 w-[200px]">
                            <ul className="text-left">
                                <div className="p-3">
                                    <label htmlFor="language" className="block text-sm font-medium text-gray-700">{app.word('language')}</label>
                                    <select className="w-30" value={app.initial.languageId}
                                        onChange={e => setLanguage(props.setInitial, parseInt(e.target.value))}>
                                        {app.initial.languages.map(l => <option key={l.id} value={l.id}>{l.name}</option>)}
                                    </select>
                                </div>

                                <div className="p-3">
                                    <label htmlFor="unit" className="block text-sm font-medium text-gray-700">{app.word('unit')}</label>
                                    <select className="w-30" value={app.initial.system}
                                        onChange={e => setUnit(e.target.value as MeasurementUnit)}>
                                        <option value={MeasurementUnit.METRIC}>{app.word('metric')}</option>
                                        <option value={MeasurementUnit.IMPERIAL}>{app.word('imperial')}</option>
                                    </select>
                                </div>

                                <li className="b-t p-3 cursor-pointer hover:bg-primary-500 hover:text-white" onClick={logout}>{app.word('logout')}</li>
                            </ul>
                        </div>
                        : null}
                </div>

            </div>

            <Outlet/>
        </div>
    )
}
